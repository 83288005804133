import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: '/AppStart',
        children: [
        ]
    },
    {
        path: '/AppStart',
        name: 'AppStart',
        component: () => import('../pages/AppStart.vue')
    },
    {
        path: '/MeinCRM',
        name: 'MeinCRM',
        component: () => import('../pages/MeinCRM.vue')
    },
    {
        path: '/Accounts',
        name: 'Accounts',
        component: () => import('../pages/Accounts.vue')
    },
    {
        path: '/Kundenliste',
        name: 'Kundenliste',
        component: () => import('../pages/Kundenliste.vue')
    },
    {
        path: '/Kundenakte',
        name: 'Kundenakte',
        component: () => import('../pages/Kundenakte.vue')
    },
    {
        path: '/Potentiale',
        name: 'Potentiale',
        component: () => import('../pages/Potentiale.vue')
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
});
  
export default router;