 <template #item="{item}">
    <!-- <router-link :to="item.to" custom v-slot="{href, route, navigate}">
        <a :href="href" @click="navigate">{{route.fullPath}}</a>
    </router-link> -->

    <a :href="item.url">{{item.label}}</a>

</template>
<script>
export default {

    name: 'SnippetsBreadcrumbItem',
    computed:{
        main() {
            return this.$route.path === '/';
        }
    }
}
</script>