<template>

    <div class="flex flex-column h-full">
        <div class="flex align-items-center px-5 pt-1" style="height:60px">
           <a href="#AppStart"><img src="images/LOGO-consult.png"  alt="LOGO consult Logo" style="width:115px;height:auto;"></a>
        </div>
        
        <div class="col-12 layout-sidebar mt-7 overflow-y-auto">
            <hr class="blu" />
            <ul class="list-none p-1 pl-2 m-0 mainnav">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple blue" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="#/Accounts">
                            <i class="pi pi-users mr-3 text-lg text-primary"></i>
                            <span class="font-medium text-base">Accounts</span>
                        </a>
                        <i class="pi pi-chevron-up absolute right-0 mr-2"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800  border-1 border-transparent hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px" href="#/Kundenliste">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Kunden</span>
                            </a>
                            <ul class="list-none p-0 m-0 overflow-hidden">
                                <li>
                                    <a v-ripple class="flex align-items-center cursor-pointer p-1 pl-6 text-gray-800  border-1 border-transparent hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px" href="#/Kundenliste">
                                        <i class="pi mr-3"></i>
                                        <span class="font-small">Kundenakte</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Zielkunden</span>
                            </a>
                        </li>
                         <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Leads</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Kontakte</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Wettbewerber</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                 <i class="pi mr-3"></i>
                                <span class="font-medium">Lieferanten</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Agenten</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="blu"/> 
            </ul>
            
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple green" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-folder mr-3 text-lg text-green-400"></i>
                            <span class="font-medium text-base">Dokumente</span>
                        </a>
                        <i class="pi pi-chevron-down absolute right-0 mr-2"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-green-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                 <i class="pi mr-3"></i>
                                <span class="font-medium">E-Mails</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-green-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Dokumente</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-green-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Gesprächsbericht</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-green-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Angebote</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-green-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Tagespreise</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="green"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple yellow" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-clock mr-3 text-lg text-yellow-400"></i>
                            <span class="font-medium text-base">Zeitplan</span>
                        </a>    
                        <i class="pi pi-chevron-down absolute right-0 mr-2" ></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-yellow-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Kalender</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-yellow-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Termine</span>
                            </a>
                        </li>
                         <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-yellow-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Aufgaben</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="teal"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple teal" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-sync mr-3 text-lg text-teal-200"></i>
                            <span class="font-medium text-base">Operative</span>
                        </a>
                        <i class="pi pi-chevron-down absolute right-0 mr-2" ></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-teal-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Sendungen</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-teal-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Geschäft</span>
                            </a>
                        </li>
                         <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-teal-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Gesamtgeschäft</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="cyan"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple cyan" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-chart-line mr-3 text-lg text-cyan-700"></i>
                            <span class="font-medium text-base">Forecast</span>
                        </a>
                        <i class="pi pi-chevron-down absolute right-0 mr-2" ></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-cyan-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px" href="#/Potentiale">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Potentiale</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-cyan-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Deal Maker</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="cyan"/>  
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple pink" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-chart-pie mr-3 text-lg text-pink-500"></i>
                            <span class="font-medium text-base">Reporting</span>
                        </a>
                        <i class="pi pi-chevron-down absolute right-0 mr-2" ></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-pink-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Dashboards</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-pink-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Analysen</span>
                            </a>
                        </li>
                         <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-pink-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Abfragen</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="pink"/>
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-900 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple purple" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-900" href="https://wikipedia.de">
                            <i class="pi pi-send mr-3 text-lg text-purple-600"></i>
                            <span class="font-medium text-base">Marketing</span>
                        </a>
                        <i class="pi pi-chevron-down absolute right-0 mr-2" ></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-purple-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Mailings</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-purple-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Kampagnen</span>
                            </a>
                        </li>
                         <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800 hover:bg-purple-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Masterkampagnen</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <hr class="purple"/> 
            </ul>
         <div class="col-12 absolute subm mt-auto border-top-1 bg-white-alpha-60 border-white-alpha-60 z-5">
            <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
                <li>
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-white-alpha-60 text-gray-800 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px" href="#/MeinCRM">
                        <i class="pi pi-user mr-2"></i>
                        <span class="font-medium">Mein CRM</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-white-alpha-60 text-gray-800 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                        <i class="pi pi-user mr-2"></i>
                        <span class="font-medium">Profil</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-white-alpha-60 text-gray-800 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                        <i class="pi pi-cog mr-2"></i>
                        <span class="font-medium">Einstellungen</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-white-alpha-60 text-gray-800 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                        <i class="pi pi-sign-out mr-2"></i>
                        <span class="font-medium">Abmelden</span>
                    </a>
                </li>
            </ul>
            <a v-ripple class="m-3 px-3 py-2 flex align-items-center hover:bg-white-alpha-60 text-gray-800 cursor-pointer text-gray-800
                transition-duration-150 transition-colors p-ripple" style="border-radius: 30px"
                v-styleclass="{ selector: '@prev', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-2" style="width: 28px; height: 28px"/>
                <span class="font-medium">Mia Stanbriger</span>
                <i class="pi pi-chevron-up ml-auto"></i>
            </a>
        </div>
        </div>
       
    </div>


</template>

<script>
</script>
