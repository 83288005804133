<template>
    <div>
        <Toast />
        <DataTable  :value="users"  sortMode="multiple"  removableSort  editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table" responsiveLayout="scroll"  columnResizeMode="fit" :paginator="true" :rows="10" dataKey="id" v-model:filters="filters" filterDisplay="row" :globalFilterFields="['fname', 'madr', 'mkon', 'mbet']"  v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
        <h4>Kundenliste</h4>
            <template #header >
                <div class="grid">
                    <div class="col-12 flex justify-content-between flex-wrap ">
                        <div class="col-3 ">
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>

                        <div class="col-3" >
                            <span class="p-input-icon-left flex align-items-center justify-content-center">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value"  class="p-column-filter" placeholder="... " />
                            </span>
                        </div>
                    </div>
                        
                </div>
            </template>
            <template #empty>
                Keine Nutzer gefunden.
            </template>
            <template #loading>
                Lade Daten. Bitte warten.
            </template>
              <Column :expander="true" headerStyle="width: 3rem" />

            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
                
            </Column>
            <template #expansion="slotProps">
                <div :value="slotProps"> 
                    <div class="flex-1">
        <div class="flex flex-column flex-auto">
            
           

            <div class="p-2">
                <div class="grid">


                    
                    <div class="surface-card p-4 shadow-2 border-round">
                        <div class="font-medium text-3xl text-900 mb-3">MOTIONspeed Spedition Profil</div>
                        <div class="text-500 mb-5">Egestas sed tempus urna et pharetra pharetra massa massa ultricies.</div>
                        <ul class="list-none p-0 m-0 border-top-1 surface-border">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">Hauptkontakt</div>
                                <div class="text-900 w-full md:w-10">Karl Gruber<br />
                                +44 1932 562611
                                tom.townsend@early-bird.demo
                                    </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">Vertrieb:</div>
                                <div class="text-900 w-full md:w-10">Henry Callaghan<br />
                                +44 645 968 9999
                                tom.townsend@early-bird.demo
                                    </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-2 font-medium">Einkaufsleiter:</div>
                                <div class="text-900 w-full md:w-10 line-height-3">Herr Peter Wolf

                                </div>
                            </li>
                             <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">Logistikleiter:</div>
                                <div class="text-900 w-full md:w-10">Marianne Hood 9H8765<br /></div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">Zweiter Firmensitz</div>
                                <div class="text-900 w-full md:w-10">Surrey Rd 233, BH12 Poole, GB
                            +44 857 999 575
                            m.hood@early-bird.demo</div>
                            </li>
                      
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">EDV Nummern</div>
                                <div class="text-900 w-full md:w-10">Transport Management System: 9H8765</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-2 font-medium">Wettbewerber</div>
                                <div class="text-900 w-full md:w-10">
                                    <Tag class="mr-2" value="Algorithms" :rounded="true"></Tag>
                                    <Tag class="mr-2" severity="success" value="Schenker" :rounded="true"></Tag>
                                    <Tag class="mr-2" severity="danger" value="DHL" :rounded="true"></Tag>
                                    <Tag severity="warning" value="SQL" :rounded="true"></Tag>
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-2 font-medium">Branche</div>
                                <div class="text-900 w-full md:w-10">
                                    <div class="grid mt-0 mr-0">
                                        <div class="col-12 md:col-6">
                                            <div class="p-3 border-1 surface-border border-round surface-card">
                                                <div class="text-900 mb-2">
                                                    <i class="pi pi-info mr-2"></i>
                                                    <span class="font-medium">Achtungsvermerk</span>
                                                </div>
                                                <div class="text-700">Wertvolle Waren;  Schadensanfällig;  Bonität sehr gut;  Besondere Konditionen;</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="p-3 border-1 surface-border border-round surface-card">
                                                <div class="text-900 mb-2">
                                                    <i class="pi pi-pencil mr-2"></i>
                                                    <span class="font-medium">Stichworte</span>
                                                </div>
                                                <div class="text-700">Vertriebsaktion Winter 2013;  Messe;  Existing Customer;  Exportkontrolle;  Kundentag;  Entwicklung;y</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="p-3 border-1 surface-border border-round surface-card">
                                                <div class="text-900 mb-2">
                                                    <i class="pi pi-star-fill mr-2"></i>
                                                    <span class="font-medium">Kontakt</span>
                                                </div>
                                                <div class="text-700">
Letzter Termin:	
16.06.2021 14:00  	Herr Peter Wolf  	(Max Muster)	
Mein letzter Termin:	
21.11.2020 16:00  	Mr. Tom Townsend  	(Christian Krämer)	
Nächster Termin:	
Mein nächster Termin:</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="p-3 border-1 surface-border border-round surface-card">
                                                <div class="text-900 mb-2">
                                                    <i class="pi pi-github mr-2"></i>
                                                    <span class="font-medium">Potentiale</span>
                                                </div>
                                                <div class="text-700">Lorem, ipsum dolor sit amet consectetur adipisicing elit. </div>
                                            </div>
                                        </div>
                                                
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
               


               
                </div>
            </div>
        </div>

    
    </div>    
                </div>
            </template>
            
        </DataTable>
    </div>

</template>

<script>
import ProductService from '../service/ProductService';

import CustomerListService from '../service/CustomerListService';
import {FilterMatchMode} from 'primevue/api';
import axios from 'axios';
 
export default {

    name: 'TableListXAPI',

    data() {
        return {
            products: null,
            expandedRows: [] ,

            editingRows: [],
            columns: null,

            selectedColumns: null,
            
            filters: {
                'global': {value: '', matchMode: FilterMatchMode.CONTAINS},
                'fname': {value: 'fname', matchMode: FilterMatchMode.CONTAINS},
                'madr': {value: 'madr', matchMode: FilterMatchMode.CONTAINS},
                'mkon': {value: 'mkon', matchMode: FilterMatchMode.CONTAINS},
                'mbet': {value: 'mbet', matchMode: FilterMatchMode.CONTAINS}
            },


            id: null,
            fname: null, 
            madr: null, mkon: null, mbet: null, 
            user:null,
            users:[],

            loading1: true,
            loading2: true,

        }
    },
    productService: null,
    
   customerListService: null,
    created() {
        this.productService = new ProductService();
        this.customerListService = new CustomerListService();

        this.columns = [
            {field: 'fname', header: 'Firmenname', key:'1',order:'1'},
            {field: 'madr', header: 'Adresse', key:'2',order:'2'},
            {field: 'mkon', header: 'Hauptkontakt', key:'3', order:'3'},
            {field: 'mbet', header: 'Hauptbetreuer', key:'4', order:'4'},


        ];
        this.selectedColumns = this.columns;
        this.initFilters();

    },
    mounted() {
        this.getallEvents();
        this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    },
    methods: {
        async getallEvents(){

            try {
                const res = await axios.get(`./dummydata-k.json`);
                console.log(res);

                const users = res.data.results;
                for (const user of users) {
                let id = user.id
                let fname = user.Firmenname
                let madr = user.Hauptadresse
                let mkon = user.Hauptkontakt
                let mbet = user.Hauptbetreuer

                this.users.push({ id, fname, madr, mkon, mbet})
                
                }
           
                console.log(users);
        
            } catch (e) {
                console.error(e);
            }
        },

        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;

                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },

        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch(status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'LOWSTOCK':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },




        formatDate(value) {
            return value.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('le-de', {style: 'currency', currency: 'EUR'});
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'fname': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'madr': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'mkon': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'mbet': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },

        onRowExpand(event) {
            this.$toast.add({severity: 'info', summary: 'Kundenakte geöffnet', detail: event.data.name, life: 3000});
        },
        onRowCollapse(event) {
            this.$toast.add({severity: 'success', summary: 'Kundenakte geschlossen', detail: event.data.name, life: 3000});
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
            this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        },
        collapseAll() {
            this.expandedRows = null;
            this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        }
    },
    computed: {

    }
}
</script>
