<template>
    <div class="layout-topbar shadow-1 opacity-80 flex justify-content-between align-items-center px-5 border-bottom-1 relative lg:static z-4 bg-white-alpha-50 border-white-alpha-40" style="height:60px, backdrop-filter: blur(10px)">
        <div class="flex">
            <a v-ripple class="cursor-pointer block lg:hidden text-gray-800 mr-3 mt-1 p-ripple"
                v-styleclass="{ selector: '#app-sidebar-7', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true}">
                <i class="pi pi-bars text-4xl"></i>
            </a>
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-gray-800 p-ripple"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
            <i class="pi pi-ellipsis-v text-2xl"></i>
        </a>
        <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static px-3 py-3 lg:py-0 mr-3 lg:mr-0">
            <li class="mr-0 lg:mr-3">
                <a v-ripple class="flex p-3 lg:p-2 align-items-center text-gray-800 hover:bg-purple-50 font-medium cursor-pointer
                    transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                    <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                    <span class="block lg:hidden font-medium">Inbox</span>
                </a>
            </li>
            <li class="mr-0 lg:mr-3">
                <a v-ripple class="flex p-3 lg:p-2  align-items-center text-gray-800 hover:bg-purple-50 font-medium cursor-pointer
                    transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                    <span class="block lg:hidden font-medium">Notifications</span>
                </a>
            </li>
            <li class="border-top-1 lg:border-top-none lg:mt-0 mt-2 lg:pt-0 pt-2 border-white-alpha-40">
                <a v-ripple class="flex p-3 lg:p-2  align-items-center hover:bg-purple-50 font-medium cursor-pointer
                    transition-duration-150 transition-colors p-ripple" style="border-radius: 30px">
                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                    <div class="block lg:hidden">
                        <div class="text-gray-900 font-medium">Josephine Lillard</div>
                        <span class="text-gray-700 font-medium text-sm">Marketing Specialist</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
name: 'AppTopbar',
    
}
</script>
