<template>
    <div class="min-h-screen flex relative lg:static" style="background-image: linear-gradient(130deg, rgb(252 219 252) 0%, rgb(73 220 255) 100%);">
        <AppSidebar /> 
        <AppTopbar />
        <div class="min-h-screen flex-1 flex-column" style="max-width: 100%;">
            <AppContent />
        </div>   
    </div>
    <AppFooter />   

</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            visibleSidebar: false,
            visibleTopbarMenu: false,
            scale: 16, 
            scales: [12,13,14,15,16]
        }
    },
    methods: {
        changeTheme(event, theme, dark) {
            let themeElement = document.getElementById('theme-link');
            themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme, theme));
            this.$appState.theme = theme;
            this.$appState.dark = dark;
            event.preventDefault();
        },
        decrementScale() {
            this.scale--;
            this.applyScale();
        },
        incrementScale() {
            this.scale++;
            this.applyScale();
        },
        applyScale() {
            document.documentElement.style.fontSize = this.scale + 'px';
        },
        showTopbarMenu(event) {
            this.visibleTopbarMenu = true;
            event.preventDefault();
        }
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper', {
                    "layout-wrapper-dark": this.$appState.dark,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                }
            ]
        },
        desktopLogo() {
            if (this.$appState.dark)
                return  '-dark';
            return '';
        }
    },
    watch: {
        $route() {
            const i = this.$route.path.toString().indexOf('/');
            const last = this.$route.path.toString().indexOf('/', i+1);
            this.parentRoute = this.$route.path.toString().slice(0, last);
        }
    }
}
</script>

<style lang="scss">

</style>
