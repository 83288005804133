<template>
    <div>
        <Toast />
        <DataTable :value="users"  sortMode="multiple"  removableSort  editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table" responsiveLayout="scroll"  columnResizeMode="fit" :paginator="true" :rows="10" dataKey="id" v-model:filters="filters" filterDisplay="row" :globalFilterFields="['kname', 'abland', 'eland', 'gae', 'vkst', 'bid', 'bpro', 'vol' ]" v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
        <h4>Potentialliste</h4>
            <template #header >
                <div class="grid">
                    <div class="col-12 flex justify-content-between flex-wrap ">
                        <div class="col-3 ">
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Spalten wählen" style="width: 20em"/>
                        </div>

                        <div class="col-3" >
                            <span class="p-input-icon-left flex align-items-center justify-content-center">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="... " />
                            </span>
                        </div>
                    </div>
                        
                </div>
            </template>
            <template #empty>
                Keine Nutzer gefunden.
            </template>
            <template #loading>
                Lade Daten. Bitte warten.
            </template>
            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
                
            </Column>
            
        </DataTable>
    </div>


</template>

<script>
import ProductService from '../service/ProductService';

import CustomerListService from '../service/CustomerListService';
import {FilterMatchMode} from 'primevue/api';
import axios from 'axios';
 
export default {

    name: 'TableListPotentiale',

    data() {
        return {
            products: null,
            expandedRows: [] ,

            editingRows: [],
            columns: null,

            selectedColumns: null,
            
            filters: {
                'global': {value: '', matchMode: FilterMatchMode.CONTAINS},
                'kname': {value: 'kname', matchMode: FilterMatchMode.CONTAINS},
                'abland': {value: 'abland', matchMode: FilterMatchMode.CONTAINS},
                'eland': {value: 'eland', matchMode: FilterMatchMode.CONTAINS},
                'gae': {value: 'gae', matchMode: FilterMatchMode.CONTAINS},
                'vkst': {value: 'vkst', matchMode: FilterMatchMode.CONTAINS},
                'bid': {value: 'bid', matchMode: FilterMatchMode.CONTAINS},
                'bpro': {value: 'bpro', matchMode: FilterMatchMode.CONTAINS},
                'bprw': {value: 'bprw', matchMode: FilterMatchMode.CONTAINS},
                'vol': {value: 'vol', matchMode: FilterMatchMode.CONTAINS},


                // 'abplz': {value: 'abplz', matchMode: FilterMatchMode.CONTAINS},
                // 'date01': {value: 'date01', matchMode: FilterMatchMode.CONTAINS},
                // 'ues': {value: 'ues', matchMode: FilterMatchMode.CONTAINS},
                
                
                // 'eort': {value: 'eort', matchMode: FilterMatchMode.CONTAINS},
                // 'eplz': {value: 'eplz', matchMode: FilterMatchMode.CONTAINS},
                
                // 'banz_send': {value: 'anz_send', matchMode: FilterMatchMode.CONTAINS},
                
                // 'freq': {value: 'freq', matchMode: FilterMatchMode.CONTAINS},
                
                // 'vkst': {value: 'vkst', matchMode: FilterMatchMode.CONTAINS},
                // 'zul': {value: 'zul', matchMode: FilterMatchMode.CONTAINS},
                // 'wp': {value: 'wp', matchMode: FilterMatchMode.CONTAINS},





            },


            id:null, kname: null, abland:null, abplz:null, date01:null, ues:null, product:null, bid: null, eland:null, eort:null, eplz:null,   bpro:null, bprw:null, anz_send:null, gae:null, freq:null, vol:null,  vkst:null, zul:null, wp:null,
            users:[],

            loading1: true,
            loading2: true,

        }
    },
    productService: null,
    
   customerListService: null,
    created() {
        this.productService = new ProductService();
        this.customerListService = new CustomerListService();

        this.columns = [

            {field: 'kname', header: 'Kundenname', key:'0', order:'0'},
            {field: 'abland', header: 'Abgangsland', key:'1',order:'1'},
            {field: 'eland', header: 'Empfangsland', key:'2', order:'2'},
            {field: 'gae', header: 'Sparte', key:'3', order:'3'},
            {field: 'vkst', header: 'Verkaufsstufe', key:'4', order:'4'},
            {field: 'bid', header: 'Betreuer', key:'5', order:'5'},
            {field: 'bpro', header: 'Umsatz', key:'6', order:'6'},
            {field: 'bprw', header: 'Währung', key:'7', order:'7'},
            {field: 'vol', header: 'Ladungsart', key:'8', order:'8'},


            // {field: 'abplz', header: 'Abgangsplz', key:'2',order:'2'},
            // {field: 'date01', header: 'Datum', key:'3', order:'3'},
            // {field: 'ues', header: 'Überschrift', key:'4', order:'4'},
            
            // {field: 'eort', header: 'Empfangsort', key:'8', order:'8'},
            // {field: 'eplz', header: 'Empfangsplz', key:'9', order:'9'},
            // {field: 'anz_send', header: 'Anzahl Sendungen p.a.', key:'14', order:'14'},
            // {field: 'freq', header: 'Frequenz', key:'16', order:'16'},
            // {field: 'zul', header: 'Zuletzt geprüft am', key:'19', order:'19'},
            // {field: 'wp', header: 'Wert des Potenzials', key:'20', order:'20'},

        ];
        this.selectedColumns = this.columns;
        this.initFilters();

    },
    mounted() {
        this.getallEvents();
        this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    },
    methods: {
        async getallEvents(){

            try {
                const res = await axios.get(`./dummydata.json`);
                console.log(res);
                

                const users = res.data.results;
                
                for (const user of users) {
                    console.log(JSON.stringify(user['Wert des Potenzials']));

                let id = JSON.stringify(user['Kunden-ID'])
                let kname = user.Kundename
                let abland = user.Abgangsland

                let eland = user.Empfangsland
                let gae = JSON.stringify(user['Gesamtaufkommen p.a. Einheit'])
                let vkst = user.Verkaufsstufe
                let bid = JSON.stringify(user['Betreuer-ID'])
                let bpro = JSON.stringify(user['Gross profit'])


                let abplz = user.Abgangsplz
                let date01 = user.Datum
                let ues = JSON.stringify(user['Überschrift'])
                
                let eort = user.Empfangsort
                let eplz = user.Empfangsplz

                let bprw = JSON.stringify(user['Gross profit währung'])
                let anz_send = JSON.stringify(user['Anzahl Sendungen p.a.'])
                let freq = user.Frequenz
                let vol = JSON.stringify(user['Volumen/Schwergut'])
                
                let zul = JSON.stringify(user['Zuletzt geprüft am'])
                let wp = JSON.stringify(user['Wert des Potenzials'])




                this.users.push({ kname, id, abland, abplz, date01, ues, bid, eland, eort, eplz, bpro, bprw, anz_send, gae, freq, vol, vkst, zul, wp })
                
                }
           
                console.log(users);
        
            } catch (e) {
                //console.error(e);
            }
        },

        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;

                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },

        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch(status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'LOWSTOCK':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },




        formatDate(value) {
            return value.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('le-de', {style: 'currency', currency: 'EUR'});
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'kname': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'abland': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'eland': {value: null,  matchMode: FilterMatchMode.CONTAINS},
                'gae': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vkst': {value: null,  matchMode: FilterMatchMode.CONTAINS},
                'bid': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'bpro': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'bprw': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vol': {value: null, matchMode: FilterMatchMode.CONTAINS},




                // 'abplz': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'date01': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'ues': { value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'bid': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'eland': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'eort': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'eplz': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'bpro': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'bprw': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'anz_send': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'gae': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'freq': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'vol': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'vkst': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'zul': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'wp': {value: null, matchMode: FilterMatchMode.CONTAINS},

            }
        },

        onRowExpand(event) {
            this.$toast.add({severity: 'info', summary: 'Kundenakte geöffnet', detail: event.data.name, life: 3000});
        },
        onRowCollapse(event) {
            this.$toast.add({severity: 'success', summary: 'Kundenakte geschlossen', detail: event.data.name, life: 3000});
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
            this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        },
        collapseAll() {
            this.expandedRows = null;
            this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        }
    },
    computed: {

    }
}
</script>
