<template>
  <p>Datatables Beispiele mit Content aus src/service/...<br />
  Datatable Beispiel 1: Sortierung, Selektierung, Filterung, Paginierung, Stichwortsuche<br />
  </p>
  <DataTable :value="customers" :paginator="true" :rows="10"
      dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" v-model:filters="filters" filterDisplay="menu"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      :globalFilterFields="['name','country.name','representative.name','status']" responsiveLayout="scroll">
      <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Beispiel 1</h5>
              <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
          </div>
      </template>
      <template #empty>
          No customers found.
      </template>
      <Column selectionMode="multiple" style="min-width: 3rem"></Column>
      <Column field="name" header="Name" sortable style="min-width: 14rem">
          <template #body="{data}">
              {{data.name}}
          </template>
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
          </template>
      </Column>
      <Column field="country.name" header="Country" sortable filterMatchMode="contains" style="min-width: 14rem">
          <template #body="{data}">
              <img src="../assets/images/flag_placeholder.png" :class="'flag flag-' + data.country.code" width="30" />
              <span class="image-text">{{data.country.name}}</span>
          </template>
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by country"/>
          </template>
      </Column>
      <Column header="Agent" sortable filterField="representative" sortField="representative.name" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
          <template #body="{data}">
              <img :alt="data.representative.name" :src="'demo/images/avatar/' + data.representative.image" width="32" style="vertical-align: middle" />
              <span class="image-text">{{data.representative.name}}</span>
          </template>
          <template #filter="{filterModel}">
              <div class="p-mb-3 p-text-bold">Agent Picker</div>
              <MultiSelect v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Any" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />
                          <span class="image-text">{{slotProps.option.name}}</span>
                      </div>
                  </template>
              </MultiSelect>
          </template>
      </Column>
      <Column field="date" header="Date" sortable dataType="date" style="min-width: 8rem">
          <template #body="{data}">
              {{formatDate(data.date)}}
          </template>
          <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
          </template>
      </Column>
      <Column field="balance" header="Balance" sortable dataType="numeric" style="min-width: 8rem">
          <template #body="{data}">
              {{formatCurrency(data.balance)}}
          </template>
          <template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
          </template>
      </Column>
      <Column field="status" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
          <template #body="{data}">
              <span :class="'customer-badge status-' + data.status">{{data.status}}</span>
          </template>
          <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                      <span :class="'customer-badge status-' + slotProps.value">{{slotProps.value}}</span>
                  </template>
                  <template #option="slotProps">
                      <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                  </template>
              </Dropdown>
          </template>
      </Column>
      <Column field="activity" header="Activity" sortable :showFilterMatchModes="false" style="min-width: 10rem">
          <template #body="{data}">
              <ProgressBar :value="data.activity" :showValue="false" />
          </template>
          <template #filter="{filterModel}">
              <Slider v-model="filterModel.value" range class="p-m-3"></Slider>
              <div class="p-d-flex p-ai-center p-jc-between p-px-2">
                  <span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
                  <span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
              </div>
          </template>
      </Column>
      <Column headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
          <template #body>
              <Button type="button" icon="pi pi-cog"></Button>
          </template>
      </Column>
  </DataTable>

  <h3> Datatable Beispiel 2: selektierbare Auswahl der dargestellten Spalten</h3>
  <DataTable :value="products" responsiveLayout="scroll">
      <template #header>
          <div style="text-align:left">
              <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                  placeholder="Select Columns" style="width: 20em"/>
          </div>
      </template>
      <Column field="code" header="Code" />
      <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index"></Column>
  </DataTable>



</template>


<script>
//// Dummy data for testing: ////
import CustomerService from '../service/CustomerService';
import CountryService from '../service/CountryService';
import ProductService from '../service/ProductService';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
    
export default {
    data() {
        return {
            customers: null,
            selectedCustomers: null,
            selectedColumns: null,
            columns: null,
            products: null,
            selectedProduct: null,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'representative': {value: null, matchMode: FilterMatchMode.IN},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
                'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
            },
            floatValue: null,
            autoValue: null,
            selectedAutoValue: null,
            autoFilteredValue: [],
            calendarValue: null,
            inputNumberValue: null,
            chipsValue: null,
            sliderValue: null,
            ratingValue: null,
            colorValue: '1976D2',
            radioValue: null,
            checkboxValue: [],
            switchValue: false,
            display: false,
            listboxValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            listboxValue: null,
            dropdownValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            dropdownValue: null,
            multiselectValue: null,
            multiselectValues: [
                {name: 'Australia', code: 'AU'},
                {name: 'Brazil', code: 'BR'},
                {name: 'China', code: 'CN'},
                {name: 'Egypt', code: 'EG'},
                {name: 'France', code: 'FR'},
                {name: 'Germany', code: 'DE'},
                {name: 'India', code: 'IN'},
                {name: 'Japan', code: 'JP'},
                {name: 'Spain', code: 'ES'},
                {name: 'United States', code: 'US'}
            ],
            toggleValue: false,
            selectButtonValues1: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue1: null,
            selectButtonValues2: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue2: null,
			tieredMenuItems: [
				{
					label:'Customers',
					icon:'pi pi-fw pi-table',
					items:[
						{
							label:'New',
							icon:'pi pi-fw pi-user-plus',
							items:[
								{
									label:'Customer',
									icon:'pi pi-fw pi-plus'
								},
								{
									label:'Duplicate',
									icon:'pi pi-fw pi-copy'
								},

							]
						},
						{
							label:'Edit',
							icon:'pi pi-fw pi-user-edit'
						}
					]
				},
				{
					label:'Orders',
					icon:'pi pi-fw pi-shopping-cart',
					items:[
						{
							label:'View',
							icon:'pi pi-fw pi-list'
						},
						{
							label:'Search',
							icon:'pi pi-fw pi-search'
						},

					]
				},
				{
					label:'Shipments',
					icon:'pi pi-fw pi-envelope',
					items:[
						{
							label:'Tracker',
							icon:'pi pi-fw pi-compass'

						},
						{
							label:'Map',
							icon:'pi pi-fw pi-map-marker'

						},
						{
							label:'Manage',
							icon:'pi pi-fw pi-pencil'
						}
					]
				},
				{
					label:'Profile',
					icon:'pi pi-fw pi-user',
					items:[
						{
							label:'Settings',
							icon:'pi pi-fw pi-cog'
						},
						{
							label:'Billing',
							icon:'pi pi-fw pi-file'
						}
					]
				},
				{
					separator:true
				},
				{
					label:'Quit',
					icon:'pi pi-fw pi-sign-out'
				}
			],
			menuitems: [
				{
					label:'Customers',
					items:[
						{
							label:'New',
							icon:'pi pi-fw pi-plus',
						},
						{
							label:'Edit',
							icon:'pi pi-fw pi-user-edit'
						}
					]
				},
				{
					label:'Orders',
					items:[
						{
							label:'View',
							icon:'pi pi-fw pi-list'
						},
						{
							label:'Search',
							icon:'pi pi-fw pi-search'
						},

					]
				}
			],
			contextMenuItems: [
				{
					label: 'Save',
					icon: 'pi pi-save'
				},
				{
					label: 'Update',
					icon: 'pi pi-refresh'
				},
				{
					label: 'Delete',
					icon: 'pi pi-trash'
				},
				{
					separator: true
				},
				{
					label: 'Options',
					icon: 'pi pi-cog'
				},
            ],
            message: [],
            timelineEvents: [
                {status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg'},
                {status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7'},
                {status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800'},
                {status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B'}
            ]
        }
    },
    
    countryService: null,
    created() {
        this.countryService = new CountryService();
        this.customerService = new CustomerService();
        this.productService = new ProductService();

         this.columns = [
            {field: 'name', header: 'Name'},
            {field: 'category', header: 'Category'},
            {field: 'quantity', header: 'Quantity'}
        ];
        this.selectedColumns = this.columns;


    },
    mounted() {
        this.countryService.getCountries().then(data => this.autoValue = data);
        this.customerService.getCustomersLarge().then(data => {
            this.customers = data;
            this.customers.forEach(customer => customer.date = new Date(customer.date));
        });
        this.productService.getProductsSmall().then(data => this.products = data);
        
    },
    methods: {
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },
        toggleMenu(event) {
			this.$refs.menu.toggle(event);
        },
        onContextRightClick(event) {
            this.$refs.contextMenu.show(event);
        },
        searchCountry(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.autoFilteredValue = [...this.autoValue];
                }
                else {
                    this.autoFilteredValue = this.autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        addMessage(severity) {
            this.message = [{severity: severity, content: 'Message Detail'}]
        },
        showToast(severity) {
            this.$toast.add({severity: severity, summary: 'Message Summary', detail:'Message Detail', life: 3000});
        },
        open() {
			this.display = true;
		},
		close() {
			this.display = false;
        },
        toggle(event) {
			this.$refs.op.toggle(event);
        },
        confirmWithPopup(event) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'popup',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        confirmWithDialog() {
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        onProductSelect(event) {
            this.$refs.op.hide();
            this.$toast.add({severity:'info', summary: 'Product Selected', detail: event.data.name, life: 3000});
        },
        formatDate(value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        }
    },
    computed: {
        containerClass() {
            return ['p-grid', {
                    'p-input-filled': this.$appState.inputStyle === 'filled',
                }
            ];
        }
    }
}
</script>

<style scoped lang="scss">
.image-text {
    vertical-align: middle;
}

.p-progressbar {
    height: 4px;
}

.customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-qualified {
        background-color: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background-color: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background-color: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background-color: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background-color: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background-color: #FFD8B2;
        color: #805B36;
    }
}

.product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
}    

::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

li {
    line-height: 1.5;
}

p {
    line-height: 1.75;
}

a {
    color: #2196F3;

    &:hover {
        text-decoration: underline;
    }
}

</style>
