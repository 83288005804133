<template>
  <div class="card">
    <router-link
      class="action-icon home-icon"
      to="/"
      v-if="!main"
      v-tooltip.bottom="'Home'"
    >
      <i class="pi pi-arrow-left"></i>
    </router-link>
    <h1>Icons + selector tags</h1>

    <h5>
      Optionen: Stichwortsuche
    </h5>
    <input class="p-inputtext p-component icon-filter icon-filter" placeholder="Search an icon" data-v-a015ac4c="">

    <div class="grid icons-list" data-v-a015ac4c="">
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-align-center" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-align-center</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-align-justify" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-align-justify</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-align-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-align-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-align-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-align-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-amazon" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-amazon</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-android" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-android</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-double-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-double-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-double-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-double-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-double-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-double-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-double-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-double-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-angle-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-angle-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-apple" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-apple</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-circle-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-circle-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-circle-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-circle-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-circle-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-circle-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-circle-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-circle-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-down-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-down-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-down-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-down-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-up-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-up-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrow-up-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrow-up-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrows-h" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrows-h</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-arrows-v" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-arrows-v</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-at" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-at</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-backward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-backward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-ban" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-ban</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-bars" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-bars</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-bell" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-bell</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-bolt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-bolt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-book" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-book</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-bookmark" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-bookmark</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-bookmark-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-bookmark-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-box" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-box</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-briefcase" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-briefcase</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-building" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-building</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-calendar" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-calendar</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-calendar-minus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-calendar-minus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-calendar-plus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-calendar-plus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-calendar-times" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-calendar-times</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-camera" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-camera</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-car" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-car</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-caret-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-caret-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-caret-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-caret-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-caret-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-caret-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-caret-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-caret-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chart-bar" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chart-bar</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chart-line" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chart-line</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chart-pie" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chart-pie</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-check" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-check</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-check-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-check-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-check-square" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-check-square</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-circle-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-circle-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-circle-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-circle-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-circle-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-circle-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-circle-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-circle-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-left" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-left</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-right" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-right</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-chevron-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-chevron-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-circle-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-circle-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-clock" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-clock</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-clone" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-clone</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-cloud" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-cloud</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-cloud-download" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-cloud-download</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-cloud-upload" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-cloud-upload</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-code" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-code</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-cog" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-cog</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-comment" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-comment</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-comments" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-comments</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-compass" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-compass</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-copy" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-copy</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-credit-card" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-credit-card</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-database" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-database</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-desktop" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-desktop</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-directions" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-directions</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-directions-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-directions-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-discord" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-discord</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-dollar" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-dollar</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-download" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-download</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-eject" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-eject</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-ellipsis-h" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-ellipsis-h</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-ellipsis-v" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-ellipsis-v</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-envelope" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-envelope</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-euro" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-euro</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-exclamation-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-exclamation-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-exclamation-triangle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-exclamation-triangle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-external-link" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-external-link</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-eye" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-eye</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-eye-slash" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-eye-slash</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-facebook" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-facebook</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-fast-backward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-fast-backward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-fast-forward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-fast-forward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-file" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-file</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-file-excel" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-file-excel</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-file-pdf" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-file-pdf</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-filter" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-filter</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-filter-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-filter-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-filter-slash" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-filter-slash</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-flag" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-flag</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-flag-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-flag-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-folder" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-folder</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-folder-open" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-folder-open</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-forward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-forward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-github" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-github</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-globe" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-globe</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-google" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-google</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-hashtag" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-hashtag</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-heart" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-heart</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-heart-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-heart-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-history" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-history</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-home" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-home</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-id-card" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-id-card</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-image" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-image</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-images" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-images</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-inbox" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-inbox</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-info" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-info</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-info-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-info-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-instagram" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-instagram</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-key" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-key</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-link" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-link</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-linkedin" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-linkedin</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-list" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-list</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-lock" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-lock</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-lock-open" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-lock-open</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-map" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-map</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-map-marker" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-map-marker</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-microsoft" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-microsoft</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-minus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-minus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-minus-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-minus-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-mobile" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-mobile</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-money-bill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-money-bill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-moon" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-moon</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-palette" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-palette</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-paperclip" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-paperclip</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-pause" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-pause</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-paypal" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-paypal</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-pencil" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-pencil</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-percentage" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-percentage</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-phone" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-phone</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-play" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-play</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-plus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-plus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-plus-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-plus-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-pound" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-pound</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-power-off" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-power-off</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-prime" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-prime</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-print" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-print</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-qrcode" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-qrcode</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-question" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-question</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-question-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-question-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-reddit" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-reddit</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-refresh" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-refresh</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-replay" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-replay</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-reply" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-reply</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-save" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-save</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-search" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-search</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-search-minus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-search-minus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-search-plus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-search-plus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-send" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-send</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-server" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-server</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-share-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-share-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-shield" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-shield</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-shopping-bag" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-shopping-bag</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-shopping-cart" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-shopping-cart</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sign-in" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sign-in</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sign-out" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sign-out</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sitemap" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sitemap</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-slack" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-slack</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sliders-h" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sliders-h</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sliders-v" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sliders-v</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alpha-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alpha-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alpha-down-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alpha-down-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alpha-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alpha-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alpha-up-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alpha-up-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-alt-slash" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-alt-slash</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-amount-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-amount-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-amount-down-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-amount-down-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-amount-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-amount-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-amount-up-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-amount-up-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-numeric-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-numeric-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-numeric-down-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-numeric-down-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-numeric-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-numeric-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-numeric-up-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-numeric-up-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sort-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sort-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-spinner" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-spinner</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-star" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-star</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-star-fill" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-star-fill</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-step-backward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-step-backward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-step-backward-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-step-backward-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-step-forward" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-step-forward</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-step-forward-alt" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-step-forward-alt</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-stop" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-stop</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-stop-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-stop-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sun" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sun</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-sync" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-sync</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-table" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-table</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-tablet" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-tablet</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-tag" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-tag</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-tags" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-tags</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-telegram" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-telegram</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-th-large" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-th-large</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-thumbs-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-thumbs-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-thumbs-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-thumbs-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-ticket" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-ticket</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-times" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-times</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-times-circle" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-times-circle</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-trash" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-trash</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-twitter" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-twitter</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-undo" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-undo</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-unlock" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-unlock</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-upload" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-upload</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-user" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-user</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-user-edit" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-user-edit</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-user-minus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-user-minus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-user-plus" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-user-plus</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-users" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-users</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-video" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-video</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-vimeo" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-vimeo</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-volume-down" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-volume-down</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-volume-off" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-volume-off</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-volume-up" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-volume-up</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-wallet" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-wallet</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-whatsapp" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-whatsapp</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-wifi" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-wifi</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-window-maximize" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-window-maximize</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-window-minimize" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-window-minimize</div>
      </div>
      <div class="col-12 md:col-2 icon" data-v-a015ac4c="">
        <i class="pi pi-youtube" data-v-a015ac4c=""></i>
        <div data-v-a015ac4c="">pi-youtube</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerListService from "../service/CustomerListService";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";

export default {
  name: "IconsDocumentation",

  data() {
    return {
      editingRows: [],
      columns: null,
      statuses: [
        { label: "In Stock", value: "INSTOCK" },
        { label: "Low Stock", value: "LOWSTOCK" },
        { label: "Out of Stock", value: "OUTOFSTOCK" },
      ],

      filters: {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        id: { value: "id", matchMode: FilterMatchMode.CONTAINS },
        username: { value: "username", matchMode: FilterMatchMode.CONTAINS },
        first_name: {
          value: "first_name",
          matchMode: FilterMatchMode.CONTAINS,
        },
        last_name: { value: "last_name", matchMode: FilterMatchMode.CONTAINS },
        department: {
          value: "departement",
          matchMode: FilterMatchMode.CONTAINS,
        },
        superior: { value: "superior", matchMode: FilterMatchMode.CONTAINS },
        email: { value: "email", matchMode: FilterMatchMode.CONTAINS },
      },

      id: null,
      username: null,
      first_name: null,
      last_name: null,
      department: null,
      position: null,
      superior: null,
      email: null,
      user: null,
      users: [],

      loading1: true,
      loading2: true,
    };
  },

  // countryService: null,
  customerListService: null,
  created() {
    //this.productService = new ProductService();

    this.customerListService = new CustomerListService();

    this.columns = [
      { field: "id", header: "ID", key: "0", order: "0" },
      { field: "username", header: "Username", key: "1", order: "1" },
      { field: "first_name", header: "First Name", key: "2", order: "2" },
      { field: "last_name", header: "Last Name", key: "3", order: "3" },
      { field: "department", header: "Department", key: "4", order: "4" },
      { field: "position", header: "Position", key: "5", order: "5" },
      { field: "superior", header: "superior", key: "6", order: "6" },
      { field: "email", header: "email", key: "7", order: "7" },
    ];

    this.initFilters();
  },
  mounted() {
    this.getallEvents();
  },
  methods: {
    async getallEvents() {
      try {
        //const res = await axios.get('http://localhost:8080/response.json', {})
        const res = await axios.get(
          "backend/crm/query?models=users&fields=id,username,first_name,last_name,department,position,superior,email"
        );
        console.log(res);

        const users = res.data.results;
        for (const user of users) {
          let id = user.id;
          let username = user.username;
          let first_name = user.first_name;
          let last_name = user.last_name;
          let department = user.department;
          let position = user.position;
          let superior = user.superior;
          let email = user.email;

          // id
          // username
          // first_name
          // last_name
          // department
          // position
          // superior
          // email

          this.users.push({
            id,
            username,
            first_name,
            last_name,
            department,
            position,
            superior,
            email,
          });
        }

        console.log(users);
      } catch (e) {
        console.error(e);
      }
    },

    onCellEditComplete(event) {
      let { data, newValue, field } = event;

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },

    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },

    formatDate(value) {
      return value.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    formatCurrency(value) {
      return value.toLocaleString("le-de", {
        style: "currency",
        currency: "EUR",
      });
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        last_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        superior: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.image-text {
  vertical-align: middle;
}

.p-progressbar {
  height: 4px;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-qualified {
    background-color: #c8e6c9;
    color: #256029;
  }

  &.status-unqualified {
    background-color: #ffcdd2;
    color: #c63737;
  }

  &.status-negotiation {
    background-color: #feedaf;
    color: #8a5340;
  }

  &.status-new {
    background-color: #b3e5fc;
    color: #23547b;
  }

  &.status-renewal {
    background-color: #eccfff;
    color: #694382;
  }

  &.status-proposal {
    background-color: #ffd8b2;
    color: #805b36;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

li {
  line-height: 1.5;
}

p {
  line-height: 1.75;
}

a {
  color: #2196f3;

  &:hover {
    text-decoration: underline;
  }
}
</style>
