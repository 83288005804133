<template>            
    <div>
        <div class="card">
            <Toolbar class="mb-4">
                <template #start>
                    <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                    <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
                </template>

                <template #end>
                   <!-- <FileUpload mode="basic" name="demo[]" url="./upload" /> -->
                   <Button label="Import" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)"  />
                    <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                </template>
            </Toolbar>

            <DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" 
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">

                <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                <Column field="name" header="Name" :sortable="true" style="min-width:16rem"></Column>

                <Column field="price" header="Price" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.price)}}
                    </template>
                </Column>
                <Column field="category" header="Category" :sortable="true" style="min-width:10rem"></Column>
                
                <Column :exportable="false" style="min-width:8rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
            <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" :alt="product.image" class="product-image" v-if="product.image" />
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
                <small class="p-error" v-if="submitted && !product.name">Name is required.</small>
            </div>
            <div class="field">
                <label for="description">Description</label>
                <Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
            </div>


            <div class="field">
                <label class="mb-3">Category</label>
                <div class="formgrid grid">
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category1" name="category" value="Accessories" v-model="product.category" />
                        <label for="category1">Accessories</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category2" name="category" value="Clothing" v-model="product.category" />
                        <label for="category2">Clothing</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category3" name="category" value="Electronics" v-model="product.category" />
                        <label for="category3">Electronics</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category4" name="category" value="Fitness" v-model="product.category" />
                        <label for="category4">Fitness</label>
                    </div>
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col">
                    <label for="price">Price</label>
                    <InputNumber id="price" v-model="product.price" mode="currency" currency="USD" locale="en-US" />
                </div>
                <div class="field col">
                    <label for="quantity">Quantity</label>
                    <InputNumber id="quantity" v-model="product.quantity" integeronly />
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
        </Dialog>
	</div>






    <div class="card">
        <h4>Editierbare Kundenliste - Speicherfunktion fehlt</h4>
        <p>......</p>
        
        
        <DataTable :value="customers"  sortMode="multiple" removableSort editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table" responsiveLayout="scroll" :paginator="true" :rows="10" dataKey="id">
        

            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="true">


                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
            </Column>

        </DataTable>
        
    </div>




</template>

<script>
//// Dummy data for testing: ////
import ProductService from '../service/ProductService';
import CustomerListService from '../service/CustomerListService';

import {FilterMatchMode} from 'primevue/api';
import axios from 'axios';
 
export default {

    name: 'TableEditCRUD',

    data() {
        return {

            products: null,
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            product: {},
            selectedProducts: null,
            submitted: false,






            editingRows: [],
            columns: null,
            filters: {
                'global': {value: '', matchMode: FilterMatchMode.CONTAINS},
                'id': {value: 'id', matchMode: FilterMatchMode.CONTAINS},
                'username': {value: 'username', matchMode: FilterMatchMode.CONTAINS},
                'first_name': {value: 'first_name', matchMode: FilterMatchMode.CONTAINS},
                'last_name': {value: 'last_name', matchMode: FilterMatchMode.CONTAINS},
                // 'department': {value: 'departement', matchMode: FilterMatchMode.CONTAINS},
                // 'superior': {value: 'superior', matchMode: FilterMatchMode.CONTAINS},
                'email': {value: 'email', matchMode: FilterMatchMode.CONTAINS},
            },
            id: null,
            username: null,
            first_name: null,
            last_name: null,
            // department: null,
            // position: null,
            // superior: null,
            email: null,
            customer:null,
            customers:[],

            title: '',
            description: '',

        }
    },
    
    // countryService: null,
    productService: null,
    customerListService: null,
    created() {
        this.productService = new ProductService();

        this.customerListService = new CustomerListService();

        this.columns = [
            {field: 'id', header: 'ID', key:'0', order:'0'},
            {field: 'username', header: 'Username', key:'1',order:'1'},
            {field: 'first_name', header: 'First Name', key:'2',order:'2'},
            {field: 'last_name', header: 'Last Name', key:'3', order:'3'},
            // {field: 'department', header: 'Department', key:'4', order:'4'},
            // {field: 'position', header: 'Position', key:'5', order:'5'},
            // {field: 'superior', header: 'superior', key:'6', order:'6'},
            {field: 'email', header: 'email', key:'7', order:'7'},
        ];

        this.initFilters();

    },
    mounted() {

        this.getallCustomers();

        this.productService.getProducts().then(data => this.products = data);

    },
    methods: {
        async getallCustomers(){

            try {
                const res = await axios.get('backend/crm/query?models=users&fields=id,username,first_name,last_name,email');
                console.log(res);

                const customers = res.data.results;
                for (const customer of customers) {
                let id = customer.id
                let username = customer.username
                let first_name = customer.first_name
                let last_name = customer.last_name
                let email = customer.email


                // id
                // username
                // first_name
                // last_name
                // department
                // position
                // superior
                // email


                
                this.customers.push({ id, username, first_name, last_name, email})
                
                }
           
                console.log(customers);
        
            } catch (e) {
                console.error(e);
            }
        },
        
        updateCellEdit(){
         
                const res = axios.post('https://vue-completecourse.firebaseio.com/posts.json', {title: this.username, description: this.email});
                console.log(res);

                // const customers = res.data.results;
                // for (const customer of customers) {
                //     let id = customer.id
                //     let username = customer.username
                //     let first_name = customer.first_name
                //     let last_name = customer.last_name
                //     let email = customer.email
                //     this.customers.push({ id, username, first_name, last_name, email})
                // }
        },

        onCellEditComplete(event) {
            let { data, newValue, field } = event;


            this.updateCellEdit();

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;

                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },

        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },


        formatDate(value) {
            return value.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('le-de', {style: 'currency', currency: 'EUR'});
        },
        clearFilter() {
            this.initFilters();
        },

        
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'username': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'first_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'last_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'department': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'position': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'superior': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },


        //Product Edit + CRUD

        openNew() {
            this.product = {};
            this.submitted = false;
            this.productDialog = true;
        },
        hideDialog() {
            this.productDialog = false;
            this.submitted = false;
        },
        saveProduct() {
            this.submitted = true;

			if (this.product.name.trim()) {
                if (this.product.id) {

                    this.products[this.findIndexById(this.product.id)] = this.product;
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
                }
                else {
                    this.product.id = this.createId();
                    this.product.image = 'product-placeholder.svg';
                    this.products.push(this.product);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
                }

                this.productDialog = false;
                this.product = {};
            }
        },
        editProduct(product) {
            this.product = {...product};
            this.productDialog = true;
        },
        confirmDeleteProduct(product) {
            this.product = product;
            this.deleteProductDialog = true;
        },
        deleteProduct() {
            this.products = this.products.filter(val => val.id !== this.product.id);
            this.deleteProductDialog = false;
            this.product = {};
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;
        },
        createId() {
            let id = '';
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        },
        
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
</style>