<template>
    <div class="search">
        <span class="p-input-icon-right">
            <InputText type="text" placeholder="Suche" />
            <i class="pi pi-search" />
        </span>
    </div>
</template>

<script>
 
export default {

    name: 'SnippetsSearch',

   
}
</script>

<style scoped lang="scss">
</style>