<template>
 
        <div class="p-2 flex flex-column flex-auto"> 
            <div class="shadow-3 m-3 ml-260 mt-7 p-4 surface-card border-2 border-dotted border-round flex-auto bg-white-alpha-70 border-white-alpha-40">
                <div class="grid">
                    <div class="col-12">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
               
</template>


<script>
export default {
    name: 'AppContent',
    computed:{
        main() {
            return this.$route.path === '/';
        }
    }
}
</script>
