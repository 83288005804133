<template>
<Breadcrumb :home="home" :model="items">
   <SnippetsBreadcrumbItem />
</Breadcrumb>
</template>

<script>
export default {
    data() {
        return {
            home: {icon: 'pi pi-home', to: '/AppStart'},
            // home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: '... fix me...'},
                {label: '... I dont work.'},
            ]
        }
    },
    computed:{
        main() {
            return this.$route.path === '/';
        }
    }
}
</script>