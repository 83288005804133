//// ESSENTIAL IMPORTS ////
import { createApp, reactive } from 'vue';
import router from './router';
import App from './App.vue';

//// STRUCTURE COMPONENTS IMPORT ////
import AppTopbar from './components/AppTopbar.vue';
import AppSidebar from './components/AppSidebar.vue';
import AppContent from './components/AppContent.vue';
import AppFooter from './components/AppFooter.vue';


//// MODULES IMPORT ////
import MainNavigation from './modules/MainNavigation.vue';
import TableFullOptions from './modules/TableFullOptions.vue';
import TableEditCRUD from './modules/TableEditCRUD.vue';
import TableListKunden from './modules/TableListKunden';
import TableListPotentiale from './modules/TableListPotentiale';

import SnippetsSearch from './modules/SnippetsSearch.vue';
import SnippetsBreadcrumb from './modules/SnippetsBreadcrumb.vue';
import SnippetsBreadcrumbItem from './modules/SnippetsBreadcrumbItem.vue';
import SnippetsCharts from './modules/SnippetsCharts.vue';
import SnippetsIcons from './modules/SnippetsIcons.vue';



//// PRIMEVUE COMPONENTS IMPORTS ////
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import SplitButton from 'primevue/splitbutton';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import InlineMessage from 'primevue/inlinemessage';

//// COMPONENTS used by PrimeBlocks ////
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Carousel from 'primevue/carousel';
import Chip from 'primevue/chip';
import Galleria from 'primevue/galleria';
import InputMask from 'primevue/inputmask';
import Knob from 'primevue/knob';
import Password from 'primevue/password';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';




import Sidebar from 'primevue/sidebar';
import PanelMenu from 'primevue/panelmenu';
import Breadcrumb from 'primevue/breadcrumb';
import ColumnGroup from 'primevue/columngroup';
import Divider from 'primevue/divider';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';
import Chart from 'primevue/chart';
import Tag from 'primevue/tag';


//// OTHER EXT. LIBRARIES  IMPORTS ////
import CodeHighlight from './CodeHighlight';
import axios from 'axios';

//// BASIC STYLING IMPORTS PRIMEVUE + PRIMEFLEX + PRISMJS ////
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import './assets/themes/material/material-light/standard/indigo/theme.scss'

import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/flags.css';

//// CUSTOMER THEME IMPORT - HERE: logo ////
import './assets/themes/logo/theme.scss';
//import './assets/themes/logo/theme.css';


//// BASIC DEVELOPMENT + ROUTER CONFIG ////
router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    next();
});
const app = createApp(App);
app.config.globalProperties.$allowDownload = (process.env.NODE_ENV === 'development');
app.config.globalProperties.$appState = reactive({inputStyle: 'filled'});


/// MAIN APP CONFIG ////
app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(ConfirmationService);
app.use(router);


// window.basurl="http://localhost:8080/";

//// AXIOS + XAPI KEY SETTINGS ////
app.use(axios);
axios.defaults.headers.common["X-API-Key"] = "V,H5FjRFfQj1t3k3flHX,r-cC69_A2Zs";

// app.use(Prism);
// Prism.highlightAll(); // highlight your code on mount


//// ADDING IMPORTED COMPONENTS TO APP ////
app.component('AppTopbar', AppTopbar);
app.component('AppSidebar', AppSidebar);
app.component('AppContent', AppContent);
app.component('AppFooter', AppFooter);


//// MODULES ARE HERE DECLARED AS COMPONENTS IN ORDER TO WORK ////
app.component('MainNavigation', MainNavigation);
app.component('TableFullOptions', TableFullOptions);
app.component('TableEditCRUD', TableEditCRUD);
app.component('TableListKunden', TableListKunden);
app.component('TableListPotentiale', TableListPotentiale);
app.component('SnippetsSearch', SnippetsSearch);
app.component('SnippetsBreadcrumb', SnippetsBreadcrumb);
app.component('SnippetsBreadcrumbItem', SnippetsBreadcrumbItem);
app.component('SnippetsCharts', SnippetsCharts);
app.component('SnippetsIcons', SnippetsIcons);


//// ADDING IMPORTED PRIMEVUE COMPONENTS ////
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Checkbox', Checkbox);
app.component('Chips', Chips);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Panel', Panel);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('Slider', Slider);
app.component('SplitButton', SplitButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Toast', Toast);
app.component('ToggleButton', ToggleButton);
app.component('Timeline', Timeline);
app.component('InlineMessage', InlineMessage)

app.component('Sidebar', Sidebar);
app.component('PanelMenu', PanelMenu);

app.component('Breadcrumb', Breadcrumb);
app.component('ColumnGroup', ColumnGroup);
app.component('Divider', Divider);
app.component('FilterMatchMode', FilterMatchMode);
app.component('FilterOperator', FilterOperator);
app.component('Toolbar', Toolbar);
app.component('FileUpload', FileUpload);
app.component('Chart', Chart);
app.component('Tag', Tag);

app.component('Avatar', Avatar);
app.component('AvatarGroup',AvatarGroup );
app.component('BadgeDirective',BadgeDirective );
app.component('Badge', Badge);
app.component('Carousel', Carousel);
app.component('Chip', Chip);
app.component('Galleria', Galleria);
app.component('InputMask', InputMask);
app.component('Knob', Knob);
app.component('Password', Password);
app.component('TabMenu', TabMenu);

app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);

app.directive('styleclass', StyleClass);


//// DEFINIG MAIN APP CONTAINER ID ////
app.mount('#app');
